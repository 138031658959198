.banner-fade {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7.4rem;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgb(3, 11, 21, 0.51),
    #030b15
  );
}
