/************* Why-us-container-box **************/
.whyus-container{

.whyus {
  width: 100%;
  padding-top: 0;

}
/********* Why us title ************/
.whyus-header {
  font-weight: 600 !important;
}

.whyus-card {
  width: 100%;
  //   background: #030b15;
  border: 1px solid #121a23;
  border-radius: 15px;
  min-height: 382px;
  padding: 60px 30px;
  transition: 0.3s ease-in-out;
}
.whyus-card:hover {
  border: 1px solid #030b15;
  transition: 0.3s ease-in-out;
}
.whyus-card:hover .why-us-icon {
  border-color: var(--color-primary);
  transition: 0.3s ease-in-out;
}

.card-title {
  color: white;
  font-size: 22px !important;
  font-weight: 500 !important;
  line-height: 1.27 !important;
  margin-bottom: 24px !important;
  text-transform: none !important;
}
.card-text {
  color: #a4b4c3 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  margin-bottom: 0 !important;
}
/***************** Icon ******************/

.why-us-icon {
  align-items: center;
  background: hsla(0, 0%, 100%, 0.07);
  border: 4px solid hsla(0, 0%, 100%, 0.06);
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;
  width: 80px;
}
.why-us-icon img {
  max-width: 60%;
}


/******************** Swiper *********************/
.swiper{
    width: 100%!important;
}
.swiper-slide{
    @media (min-width: 1024px) and (max-width: 1200px) {
      margin-right: 20px!important;
      width: 23%!important;
    }
}
.swiper-pagination-bullet{
  background-color: #00c4f4!important;
}
.swiper-wrapper{
  padding: 30px  0;
}


}






