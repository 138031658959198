.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .project-intro {
    display: flex;
    width: 100%;
    gap: 30px;
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .title-intro-btn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
  .project-title-wrapper {
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .project-desc-wrapper {
    padding-top: 55px;
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
      padding-top: 0;
    }
  }
  .project-header-text {
    color: white;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 26px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .project-grid {
    width: 100%;
  }
  .project-card-wrapper {
    width: 100%;
    position: relative;
  }
  .project-card-wrapper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    // background: linear-gradient(
    //   90deg,
    //   #2e2341 18.75%,
    //   rgba(0, 0, 0, 0.17) 100%
    // );
    background: linear-gradient(
      90deg,
      rgba(0, 89, 111, 0.4) 18.75%,
      rgba(0, 0, 0, 0.17) 100%
    );
  }
  .project-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 45px 27px 20px 27px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;
  }
  .project-title {
    font-size: 20px;
    color: #fff;
    // max-width: 55%;
    height: 50px;
    //margin-bottom: 9px;
    line-height: 1.27;
    font-weight: 700;
    @media (max-width: 1024px) {
      font-size: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }
  .project-desc {
    font-size: 16px;
    font-weight: 400;
    color: #fefefe;
    max-width: 80%;
    line-height: 24px;
    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
  .project-img {
    width: 100%;
  }

  .project-img img {
    width: 100%;
    //height: 100%;
    object-fit: cover;
    object-position: center;
    margin-bottom: -5px;
  }

 
  ////////////////////////
  // .project-left:after {
  //   position: absolute;
  //   content: "";
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba($color: #000000, $alpha: 0.6);
  // }
  // .project-left img {
  //   width: 100%;
  // }
  // .project-right {
  //   margin-left: -150px;
  //   z-index: 9;
  // }
  // .prod-card {
  //   width: 100%;
  //   //height: 400px;
  // }

  // .prod-card img {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  //   object-position: center;
  //   border-radius: 6px;
  // }

  // .prod-card-content {
  //   position: absolute;
  //   left: 35px;
  //   bottom: 45px;
  // }
  // .prod-card-title {
  //   color: white;
  //   font-size: 22px !important;
  //   font-weight: 500 !important;
  //   line-height: 1.27 !important;
  //   margin-bottom: 24px !important;
  // }
  // .prod-card-text {
  //   color: #a4b4c3 !important;
  //   font-size: 15px !important;
  //   line-height: 22px !important;
  //   margin-bottom: 0 !important;
  // }
  // // Swiper

  // .swiper-slide {
  //   margin-right: 15px !important;
  // }
  // .swiper-wrapper {
  //   justify-content: flex-end !important;
  // }
  // // Override MUI Styles

  // // .css-6gs9hh{
  // //   width: 100%!important;
  // // }
  // .css-18467a {
  //   width: 100%;
  // }
  // .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  //   //bottom: -2px!important;
  //   //top: -2px!important;
  // }
}
