.about-comp-wrapper {
    display: flex;
    justify-content: center;

    .about-comp {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        gap: 40px;

        @media (max-width: 900px) {
            flex-direction: column-reverse;
            align-items: center;
            gap: 20px;
        }

        .left,
        .right {
            width: 50%;
            max-width: 570px;

            @media (max-width: 900px) {
                width: 100%;
            }
        }

        .left {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .right {
            .top {
                .title {
                    font-weight: 700;

                    @media (max-width: 600px) {
                        font-size: 25px;
                    }
                }

                .text {
                    margin-top: 20px;
                    font-size: 16px;
                    color: #a4b4c3;
                    line-height: 20px;
                    font-weight: 300;

                    @media (max-width: 600px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }

            .bottom {
                .bottom-top {
                    display: flex;
                    border-bottom: 1px solid rgba(0, 196, 244, 0.2);
                    margin-top: 20px;

                    button {
                        font-size: 16px;
                        font-weight: 400;
                        text-transform: capitalize;
                        padding: 10px 20px;
                        color: #a4b4c3;
                    }

                    button:hover,
                    .active {
                        background-color: rgba(0, 196, 244, 0.1);
                        border-radius: 0;
                        color: var(--color-primary);
                    }
                }

                .bottom-text-wrapper {
                    min-height: 80px;
                    margin-top: 20px;
                }

                .bottom-text {
                    border-left: 1px solid var(--color-primary);
                    padding-left: 20px;
                    font-size: 16px;
                    color: #a4b4c3;
                    line-height: 20px;
                    font-weight: 300;
                    height: fit-content;
                    @media (max-width: 600px) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }

            }
        }
    }
}

.manager-speech-wrapper {
    display: flex;
    justify-content: center;

    .manager-speech {
        width: 100%;
        display: flex;
        gap: 40px;

        @media (max-width: 900px) {
            flex-direction: column-reverse;
            align-items: center;
            gap: 20px;
        }

        .left {
            width: 35%;
        }
        .right {
            width: 65%;
        }
        .left,
        .right {
            
            @media (max-width: 900px) {
                width: 100%;
                max-width: 570px;
            }
        }

        .left {
            box-shadow: 0 0 8px 8px #030b15 inset;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .right {
            .main-title {
                font-weight: 700;

                @media (max-width: 600px) {
                    font-size: 25px;
                }
            }

            .sub-title {
                font-size: 16px;
                color: var(--color-primary);
                margin-top: 10px;

                @media (max-width: 600px) {
                    font-size: 14px;
                }
            }

            .text {
                margin-top: 20px;
                font-size: 16px;
                color: #a4b4c3;
                line-height: 20px;
                font-weight: 300;

                @media (max-width: 600px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}

.banner-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 11;
        background-color: rgba(3, 11, 21,0.4);
    }
    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
            width: max-content;
            z-index: 22;
            font-weight: 700;
            font-size: 50px;
            color: white;
            padding: 40px 0;
            @media (max-width: 1200px) {
                font-size: 40px;
            }
            @media (max-width: 900px) {
                font-size: 30px;
            }
        }
    }
    .img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}