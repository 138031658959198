.home-services-wrapper {
    display: flex;
    justify-content: center;

    .home-services {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // padding-bottom: 0;
        .top {
            display: flex;
            flex-direction: column;
            align-items: center;

            .our-service-top-btn {
                border-radius: 20px;
                background-color: rgba(0, 196, 244, 0.2);
                text-transform: capitalize !important;
                min-width: 140px;
                font-size: 15px;
                line-height: 22px;
                color: var(--color-primary);
            }

            .top-header {
                font-weight: 600 !important;
                margin-top: 20px;
                @media (max-width: 600px) {
                    font-size: 20px;
                }
            }
        }

        .bottom {
            margin-top: 60px;
            width: 100%;
            justify-content: space-between;
            // margin: 60px;
            @media (max-width: 900px) {
                // margin-top: 50px;
            }
            @media (max-width: 600px) {
                // margin-top: 40px;
                justify-content: center;
            }
            .card {
                max-width: 370px;
                width: 100%;
                border-radius: 5px;
                overflow: hidden;
                // margin-bottom: 40px;
                padding: 0 !important;
                .card-img {
                    width: 100%;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: transform .5s ease-in-out; 
                    }
                }

                .card-content {
                    z-index: 22;
                    width: 100%;
                    height: 260px;
                    padding: 30px 45px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    margin-top: -3px;
                    // background: #030b15;
                    background-color:rgba(3, 11, 21,0.98);
                    border: 1px solid rgba(3, 11, 21,0.9);
                    @media (max-width: 600px) {
                        padding: 20px;
                        height: auto;
                    }
                    .card-top {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        .icon-wrapper {
                            width: 85px;
                            height: 85px;
                            min-width: 85px;
                            @media (max-width: 1200px) {
                                width: 65px;
                            height: 65px;
                            min-width: 65px;
                            }
                            @media (max-width: 600px) {
                                width: 45px;
                            height: 45px;
                            min-width: 45px;
                            }
                            img {
                                width: 100%;
                                height: 100%;
                                transition: transform .5s ease-in-out;
                        } 
                        }
                       
                        h3 {
                            font-size: 22px;
                            font-weight: 700;
                            color: white;
                            @media (max-width: 600px) {
                                font-size: 18px;
                            }
                        }
                    }

                    .card-bottom-text {
                        margin-top: 10px;
                        width: 100%;
                        font-size: 18px;
                        color: #a4b4c3;
                        line-height: 25px;
                        font-weight: 300;
                        @media (max-width: 1200px) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    .left-bar,
                    .right-bar {
                        width: 7px;
                        height: 0px;
                        background-color: var(--color-primary);
                        position: absolute;
                    }

                    .left-bar {
                        left: 0;
                        top: 0;
                    }

                    .right-bar {
                        right: 0;
                        bottom: 0;
                    }

                }

                .bottom-btn {
                    width: 100%;
                    height: 60px;
                    background-color: var(--color-primary);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .bottom-btn-text {
                        font-weight: 700;
                        color: white;
                        font-size: 16px;
                    }
                }
            }

            // .card:last-child {
            //     margin-bottom: 0;
            // }

            .card:hover {
                cursor: pointer;

                .left-bar,
                .right-bar {
                    height: 260px;
                    transition: height 500ms ease-in-out;
                }

                .card-img {
                    overflow: hidden;

                    img {
                        transform: scale(1.1);
                        transition: transform .5s ease-in-out; 
                    }
                }
                .icon-wrapper {
                    img {
                        transform: scaleX(-1);
                        transition: transform .5s ease-in-out;
                    }
                }
            }
        }
    }
}