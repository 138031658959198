.partner-header {
  font-weight: 600 !important;
}
.partner-header-box {
  padding-top: 0;
}
// .grid-wrapper {
//   width: 100%!important;
// }

.partner-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  border: 1px solid hsla(0, 0%, 100%, 0.06);
  cursor: pointer;
  display: flex;
  height: 123px;
  padding: 20px !important;
  opacity: 0.65;
  margin-left: -1px;
  margin-top: -1px;
  @media(max-width:599px){
    opacity: 1;
  }
}
.partner-grid:hover {
  opacity: 1;
  transition: all 0.3s ease-in;
}
.partners-img {
  width: 110px;
  margin: auto;
  @media(max-width:599px){
    width: 130px;
  }
}
.partners-img img {
  width: 100%;
  height: 100%;
  align-items: center;
}
.partner-grid {
  width: 100% !important;
  padding: 0 !important;
}
.css-1u2svpn-MuiGrid-root{
  width: 100%!important;
  margin-left: 0!important;
}
.css-1u2svpn-MuiGrid-root{
  @media (max-width: 600px) {
    margin-top: -30px!important;
  }
}