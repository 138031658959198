.project-intro-main {
  display: flex;
  width: 100%;
  gap: 30px;
  //padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
 
}
.css-10q9k9w-MuiGrid-root{
  @media (min-width: 465px) and (max-width: 600px) {
      width: 80%!important;
      margin: auto!important;
    }
}
// Project On Hover

.project-content-hover {
  opacity: 0;
}
.project-card-wrapper .project-content-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  transition: 0.3s cubic-bezier(0.24, 0.74, 0.58, 1);
  transform: scaleY(0);
  transform-origin: 0 0 0;
}
.project-card-wrapper:hover .project-content-hover {
  opacity: 1;
  transform: scaleY(1);
  cursor: pointer;
}
.project-content-hover .project-hover-top {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  background-color: #fff;
}
.proj-title {
  flex-grow: 1;
  padding-right: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 56%;
  padding: 20px 20px 0 20px;
  @media (max-width: 1100px) {
    padding: 10px 10px 0 10px;
  }
  @media (min-width: 700px) and (max-width: 800px) {
    max-width: 65%;
  }
  @media (min-width: 900px) and (max-width: 975px) {
    max-width: 75%;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    max-width: 80%;
  }
  @media (max-width: 325px) {
    max-width: 75%;
  }
  h4 {
    font-size: 16px;
    margin-bottom: 9px;
    @media (max-width: 1100px) {
      font-size: 14px;
    }
    color: #000;
    max-width: 100%;
    line-height: 1.27;
  }
}
.project-hover-bottom .proj-hover-content:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: linear-gradient(90deg, #006079 28.96%, #00c4f4 64.12%);
  opacity: 0.15;
  
}
.proj-hover-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.proj-hover-content p {
  padding: 20px;
  //padding-top: 40px;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 1100px) {
    font-size: 12px;
    line-height: 16px;
  }
}
.project-small-img {
  max-width: 170px;
  margin-bottom: -5px;
  max-width: 45%;
  height: 100%;

  @media (min-width: 700px) and (max-width: 800px) {
    max-width: 35%;
  }
  @media (min-width: 900px) and (max-width: 975px) {
    max-width: 25%;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    max-width: 20%;
  }
  @media (max-width: 325px) {
    max-width: 25%;
  }
}
.project-small-img img {
  height: auto;
  max-width: 100%;
  height: 100%;
}
