.slider-container {
  height: 70vh;
  // @media screen and (max-width: 768px) {
  //   height: 60vh;
  // }
  // @media screen and (max-width: 576px) {
  //   height: 50vh;
  // }
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  z-index: 1;
}
.slider-container::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: 100%;
  width: 100%;
  position: relative;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  object-position: center;
}
.slider-headline-body {
  display: flex;
  justify-content: center;
  //align-items: center;
}

// Arrows
.prev,
.next {
  color: rgb(203, 203, 203);
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  border-radius: 0px 5px 5px 0px;
  @media (max-width:768px) {
    display: none;
  }
}

.slider-container:hover .prev:hover,
.slider-container:hover .next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0px 3px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  display: inline-block;
}



.active-dot {
  background-color: #184189;
}


// Test

 .hero-content {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // align-items: flex-start;
  // justify-content: flex-start;
  // top: 50%;
  transform: translateY(50%);
  z-index: 10;
}

.hero-content h1 {
  color: #fff;
  width: 100%;
}


.banner-fade{
  position: absolute;
  bottom: 0;
  width: 100%;
  //height: 7.4rem;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgb(3, 11, 21, 0.61),
    #030b15
  );
}