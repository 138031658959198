.footer-container {
  // width: 100%;
  padding: 0 21px;
  @media screen and (min-width: 1025px) and (max-width: 1450px) {
    padding: 0 41px;
  }
  background-color: #0a0b14;
  background-image: linear-gradient(359.43deg, #0b1d33 11.06%, #030b15 99.43%);
  > * {
    font-family: Inter, san-serif;
  }
}
.footer-wrapper {
  // max-width: 1280px;
  // width: 100%;
  margin: auto;
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  //align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 48px 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
  @media (max-width: 768px) {
    padding: 25px 0;
  }
}
.menu-wrapper {
  display: flex;
  width: 50%;
  gap: 70px;
  @media (max-width: 1024px) {
    justify-content: center;
    gap: 80px;
    padding-bottom: 20px;
  }
  @media (min-width: 1025px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    gap: 10%;
  }
}
.menu {
  @media (min-width: 1025px) {
    justify-content: left;
  }
}
.menu-lists {
  list-style: none;
  white-space: nowrap;
  text-decoration: none;
}
.menu-lists a {
  text-decoration: none;
}
ul {
  padding: 0;
  // > * {
  //   .menu-lists a:hover {
  //     color: var(--color-primary);
  //   }
  // }
}
// .menu-lists a:hover {
//   color: var(--color-primary);
// }
.menu-lists {
  vertical-align: baseline;
}
.menu {
  @media (max-width: 768px) {
    width: 40%;
  }
}
.prod-menu {
  @media (max-width: 768px) {
    width: 40%;
  }
  @media (max-width: 380px) {
    width: 55%;
  }
}

.footer-intro {
  width: 40%;
  @media (max-width: 1024px) {
    width: 40%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}

.footer-icon:hover {
  > * {
    color: var(--color-primary) !important;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
}
.footer-icon:hover {
  border: 1px solid var(--color-primary) !important;
}
.contact-menu {
  width: 35%;
  @media (max-width: 1024px) {
    width: 100%;
    margin: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: auto;
  }
}
.footer-intro-text {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  margin-bottom: 20px;
  //margin-top: 20px;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
.footer-intro-text2 {
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 0;
    width: 100%;
  }
}
/* Icons - Logo */
.footer-logo {
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
}
.footer-logo-wrap {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
}
.logo-text {
  color: white;
  margin: 0;
}
.footer-logo-img {
  width: 60px;
  //height: 40px;
}
.footer-logo-img img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  //max-width: 100px;
}
.footer-social-icons {
  display: flex;
  gap: 10px;
  @media (max-width: 1024px) {
    padding-bottom: 20px;
  }
}

.footer-icon {
  padding: 7px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
}

/* Menu*/

.menu-title h5 {
  font-weight: 500;
  font-size: 20px;
  color: white;
  margin-bottom: 20px;
  margin-top: 0;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.menu-item {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 4px;
  }
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.menu-item:hover {
  color: var(--color-primary);
  transition: all 0.3s ease-in;
}

.contact-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Footer Bootom*/

.footer-bottom {
  width: 100%;
  margin: auto;
  padding: 25px 0;
}
.copy-right {
  font-size: 14px;
}
.copy-right,
.terms ul li {
  color: rgb(138, 140, 162);
}

.copy-right {
  @media (max-width: 768px) {
    margin-bottom: 10px;
    text-align: center;
  }
}
.terms-list {
  display: flex;
  gap: 10px;
  cursor: pointer;
  li:last-child::before {
    content: "|";
    padding-right: 10px;
    color: #036eb6;
    font-size: 12px;
  }
}
.terms-list li:hover {
  color: #036eb6;
  transition: all 0.3s ease-in;
}
.intro-menu-wrapper {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 1024px) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }
}
/******************** Footer Scroll to Top ******************************/
.footer-scroll-box {
  justify-content: center;
  margin: auto;
  position: relative;
  z-index: 1;
}
.footer-scroll-box:before {
  background: #1f262f;
  content: "";
  height: 1px;
  //left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  z-index: -1;
}
.footer-scroll-box .footer-scroller {
  align-items: center;
  background: #030b15;
  border: 1px solid #1f262f;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 18px;
  height: 66px;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 66px;
  cursor: pointer;
}

.footer-scroll-box .footer-scroller:hover {
  background: var(--color-primary);
  text-decoration: none;
  transition: all 0.6s ease-in-out;
}
