.navbar-wrapper {
    .navbar {
        background-color: #0a0b14 !important;
    }

    .logo {
        text-decoration: none !important;

        button {
            padding: 0 !important;

            img {
                width: 60px;

                @media (max-width: 600px) {
                    width: 50px;
                }
            }

            div {
                display: flex;
                flex-direction: column;

                span {
                    font-size: 16px;
                    text-align: left;
                    font-weight: 700;
                    color: #fff;

                    @media (max-width: 600px) {
                        font-size: 14px;
                    }

                }
            }


        }
    }

    .nav-link {
        color: #f2f2f2 !important;
    }

    .nav-link:hover {
        color: var(--color-primary) !important;
    }

    .active {
        >* {
            color: var(--color-primary) !important;
        }
    }
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    background-color: #0a0b14ee !important;
    width: 100vw !important;
    // height: 100vh;
    // left: 0 !important;
    top: 60px !important;

    @media (max-width: 600px) {
        top: 46px !important;
    }

    right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.css-1qafyuu-MuiToolbar-root,
.css-hd4j5r-MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.css-1uzr472-MuiPaper-root-MuiAppBar-root,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    padding-left: 24px !important;
    padding-right: 24px !important;

    @media (max-width: 600px) {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

.menu-dropdown {
    width: 100%;
    .dropdown-icon {
        width: 20px;
        height: 20px;
    }
    li {
        border-bottom: 1px solid #f2f2f20c !important;
        font-size: 16px;
        @media (max-width: 600px) {
            font-size: 14px;
        }
        span:first-child {
            padding-right: 10px;
        }
    }

}
.menu-dropdown:last-child {
    li {
        border-bottom: 0 !important;
    }
}