.home-about-wrapper {
    display: flex;
    justify-content: center;
    background-color: black;
    // box-shadow: 0 16px 25px #030b15;
    // background-color: #030b15;
    // background: rgb(3,11,21);
    // background: linear-gradient(90deg, rgba(3,11,21,1) 0%, rgba(0,0,0,1) 20%, rgba(3,11,21,1) 40%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 80%, rgba(3,11,21,1) 90%, rgba(3,11,21,1) 100%);
    .home-about {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 30px;

        @media (max-width: 900px) {
            flex-direction: column;
        }

        .left,
        .right {
            width: 50%;
            max-width: 570px;
            @media (max-width: 900px) {
                width: 100%;
            }
        }

        .left {
            h2 {
                font-weight: 700;
                color: white;

                @media (max-width: 600px) {
                    font-size: 24px;
                }
            }

            p {
                color: #a4b4c3 !important;
                margin-top: 20px;
                line-height: 2;
                max-width: 600px;
                font-size: 15px !important;
                line-height: 22px !important;
            }

            button {
                margin-top: 40px;
            }
        }

        .right {
            max-width: 570px;
            max-height: 320px;
            // box-shadow: 0 10px 35px rgba(0, 196, 244,0.19);
            video {
                width: 100%;
                height: 100%;
            }
        }
    }
}