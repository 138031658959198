.contact-comp-wrapper {
    display: flex;
    justify-content: center;

    .contact-comp {
        width: 100%;
        .top {
            display: flex;
            flex-direction: column;
            align-items: center;

            .title-top-btn {
                border-radius: 20px;
                background-color: rgba(0, 196, 244, 0.2);
                text-transform: capitalize !important;
                min-width: 140px;
                font-size: 15px;
                line-height: 22px;
                color: var(--color-primary);
            }

            .top-title {
                font-weight: 700;
                margin-top: 20px;
                font-size: 35px;
                @media (max-width: 900px) {
                    font-size: 30px;
                }
                @media (max-width: 600px) {
                    font-size: 25px;
                }
                text-align: center;
            }
        }

        .contact-info {
            display: flex;
            gap: 20px;
            margin-top: 40px;
            margin-bottom: 40px;
            @media (max-width: 900px) {
                flex-direction: column;
                align-items: center;
            }

            .contact-box {
                width: 33.3%;

                @media (max-width: 900px) {
                    width: 100%;
                }

                display: flex;
                flex-direction: column;
                align-items: center;

                .icon-outer {
                    width: 100px;
                    height: 100px;

                    @media (max-width: 600px) {
                        width: 80px;
                        height: 80px;
                    }

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    border: 1px dotted rgba(255, 254, 254, 0.2);

                    .icon {
                        width: 80px;
                        height: 80px;
                        @media (max-width: 600px) {
                            width: 60px;
                            height: 60px;
                        }
    
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .icon-inner {
                            width: 40px;
                            height: 40px;
                            @media (max-width: 600px) {
                                width: 30px;
                                height: 30px;
                            }
        
                        }
                    }
                }

                .contact-box-texts {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    h3 {
                        font-size: 16px;
                        color: white;
                        text-align: center;
                        max-width: 250px;
                    }
                }
            }

            .email {
                border: 3px solid yellow;

                .email-inner {
                    color: yellow;
                }
            }

            .phone {
                border: 3px solid red;

                .phone-inner {
                    color: red;
                }
            }

            .address {
                border: 3px solid var(--color-primary);

                .address-inner {
                    color: var(--color-primary);
                }
            }
        }
        .contact-form {
            // background-color: #052348;
            background-image: url("../../assets/WaveBg.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding: 60px 40px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            @media (max-width: 900px) {
                padding: 30px 20px;
            }
            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root, .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
                color: rgb(180, 180, 180);
                font-size: 16px;
            }
            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                border: 1px solid rgba(201, 201, 201, 0.24);
                border-radius: 5px;
                overflow: hidden;
            }
            .form-top {
                display: flex;
                gap: 20px;
                width: 100%;
                @media (max-width: 900px) {
                    flex-direction: column;
                }
                .form-name, .form-email {
                    width: 50%;
                    @media (max-width: 900px) {
                        width: 100%;
                    }
                }
            }
            .form-msg {
                width: 100%;
                margin-top: 20px;
            }
            .form-name , .form-email , .form-msg {
                background-color: #030b15;
                color: white !important;
                border-radius: 5px;
                
            }
            .contact-btn {
                // border-radius: 50px;
                background-color: #030b15;
                text-transform: capitalize !important;
                min-width: 160px;
                min-height: 50px;
                font-size: 15px;
                line-height: 22px;
                // border: 3px solid var(--color-primary);
                // color: white;
                color: var(--color-primary);
                border: 1px solid var(--color-primary);
                margin-top: 40px;
                @media (max-width: 900px) {
                    margin-top: 30px;
                    min-width: 140px;
                    min-height: 40px;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
        .contact-map {
            height: 400px;
            margin-top: 40px;
            @media (max-width: 600px) {
                height: 300px;
            }
            width: 100%;
           > iframe {
            width: 100%;
            height: 100%;
            filter: invert(90%);
            border: 0;
           }
        }
    }
}