/* Colors*/
:root {
  --color-primary: #00c4f4;
  --color-secondary: #-----;
  --color-accent1: #------;
  --color-accent2: #------;
  --color-accent3: #------;
  --color-accent4: #------;
  --color-primary-dark: #------;
  --color-logo-1: #FF0000;
  --color-logo-2: #0000FF;
  --color-logo-3: #FFFF00;
  //#009cad
}
.app {
  overflow-x: hidden;
  // width: 100vw;
}
html {
  font-family: "Poppins", sans-serif !important;
  font-size: 62.5%;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #030b15;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.colored-title {
  color: var(--color-primary);
}
h1 {
  font-size: 40px;
}
h2 {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600!important;
  margin-top: 0;
}
.tb-space {
  padding: 100px 24px;

  @media (max-width: 900px) {
    padding: 60px 24px;
  }

  @media (max-width: 600px) {
    padding: 40px 16px;
  }
}

.btn-outlined {
  min-width: 130px !important;
  min-height: 35px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
}

.title-top-btn {
  border-radius: 20px!important;
  background-color: rgba(0, 196, 244, 0.2)!important;
  text-transform: capitalize !important;
  min-width: 140px!important;
  font-size: 15px!important;
  line-height: 22px!important;
  color: var(--color-primary)!important;
}