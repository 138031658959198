.solution-container-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .solution-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 700px;

            .our-service-top-btn {
                border-radius: 20px;
                background-color: rgba(0, 196, 244, 0.2);
                text-transform: capitalize !important;
                min-width: 140px;
                font-size: 15px;
                line-height: 22px;
                color: var(--color-primary);
            }

            .text {
                margin-top: 20px;
                font-size: 16px;
                color: #a4b4c3;
                line-height: 20px;
                font-weight: 300;
                // text-align: center;

                @media (max-width: 600px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            .btn-container {
                display: flex;
                border-bottom: 1px solid rgba(0, 196, 244, 0.2);
                margin-top: 20px;
                flex-wrap: wrap;
                .active {
                    background-color: rgba(0, 196, 244, 0.1);
                    border-radius: 0;
                    color: var(--color-primary);
                }
                button {
                    font-size: 16px;
                    font-weight: 400;
                    text-transform: capitalize;
                    padding: 10px 20px;
                    color: #a4b4c3;
                }

                button:hover,
                .active {
                    background-color: rgba(0, 196, 244, 0.1);
                    border-radius: 0;
                    color: var(--color-primary);
                }
            }
        }
        .cards-container {
            margin-top: 40px;
            justify-content: center;
            align-items: center;
            width: 100%;
            .grid-item {
                margin: 0;
                width: fit-content;
                display: flex;
                justify-content: center;
            }
        }
    }
}

.solution-card {
    max-width: 628px;
    width: 100%;
    border-radius: 10px;
    background-color:rgba(3, 11, 21,0.98);
    box-shadow: 0 6px 16px rgba(0, 196, 244,0.1);
    padding: 30px 40px;
    padding-top: 40px;
    position: relative;
    height: 240px;
    @media (max-width: 1200px) {
        height: 270px;
    }
    @media (max-width: 1025px) {
        height: 290px;
    }
    @media (max-width: 900px) {
        height: auto;
    }
    @media (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 30px;
    }
    .icon-wrapper {
        width: 70px;
        height: 70px;
        border-radius: 5px;
        background-color: var(--color-primary);
        position: absolute;
        left: 40px;
        top: -30px;
        @media (max-width: 600px) {
            left: 16px;
            width: 60px;
            height: 60px;
        }
        img {
            width: 100%;
            height: 100%;
            padding: 16px;

        }
    }

    .card-title {
        color: white;
        font-weight: 700;
        margin-top: 20px;
        font-size: 25px;
        @media (max-width: 600px) {
            font-size: 20px;
        }
    }
    .card-text {
        margin-top: 20px;
        font-size: 16px;
        color: #a4b4c3;
        line-height: 20px;
        font-weight: 300;
        @media (max-width: 600px) {
            font-size: 14px;
            line-height: 18px;
        }
    }
}